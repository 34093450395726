import { MatterType, TagType } from "@/helpers/types";
import { FunctionComponent, useState } from "react";
import DesktopLargeItem from "./DesktopLargeItem";
import styles from "../../styles/components/IndexPage/DesktopLargeItems.module.scss";
import dynamic from "next/dynamic";
const BlurContainer = dynamic(() => import("../BlurContainer"), {
  ssr: false,
});

type PropsType = {
  items: (MatterType | TagType)[];
};

const DesktopLargeItems: FunctionComponent<PropsType> = ({ items }) => {
  const [activeItem, setActiveItem] = useState(items[0]);
  const isSingle = items.length === 1;

  return (
    <div className={styles.blockContainer}>
      <div
        className={styles.img}
        style={{ backgroundImage: `url(${activeItem.image?.image_1920})` }}
      />
      <div
        data-testid="desktop_large_container"
        className={styles.textItemsContainer}
      >
        {items.map((el, i) => (
          <DesktopLargeItem
            key={i}
            item={el}
            isActive={!isSingle && el.id === activeItem.id}
            isSingle={isSingle}
            onChange={(id) => {
              const item = items.find((el) => el.id === id);
              if (item) setActiveItem(item);
            }}
          />
        ))}

        <BlurContainer
          data-testid="BlurContainer"
          image={activeItem.image?.image_1920 as string}
        />
      </div>
    </div>
  );
};

export default DesktopLargeItems;
