import { FunctionComponent } from "react";
import { MainPageDataType, MatterType, TagType } from "@/helpers/types";
import DesktopLargeItems from "./DesktopLargeItems";
import DesktopSmallItem from "./DesktopSmallItem";
import dynamic from "next/dynamic";
import styles from "../../styles/components/IndexPage/DayPictureDesktop.module.scss";
const DesktopStory = dynamic(() => import("./DesktopStory"));
import classNames from "classnames";

type PropsType = {
  firstItem: MatterType | TagType;
  otherItems: MatterType[];
  secondStory?: TagType;
  dayPictureType: MainPageDataType["day_picture_type"];
};

const DayPictureDesktop: FunctionComponent<PropsType> = ({
  firstItem,
  otherItems,
  secondStory,
  dayPictureType,
}) => {
  let largeItems: (MatterType | TagType)[];
  let smallItems: MatterType[];
  let smallItemClassName: string;
  switch (dayPictureType) {
    case "main_1_minor_5":
      largeItems = [firstItem];
      smallItems = [...otherItems];
      smallItemClassName = "basis-[20%]";
      break;
    case "main_2_minor_4":
      largeItems = [firstItem, otherItems[0]];
      smallItems = otherItems.filter((el, i) => i > 0);
      smallItemClassName = "basis-[25%]";
      break;
    case "main_3_minor_3":
      largeItems = [firstItem, otherItems[0], otherItems[1]];
      smallItems = otherItems.filter((el, i) => i > 1);
      smallItemClassName = "basis-[33%]";
  }

  return (
    <div
      className={`mt-[15px] mb-[70px] bg-[#000] bg-opacity-[0.93] pb-[${
        smallItems.length ? "110px" : "0px"
      }]`}
    >
      <DesktopLargeItems items={largeItems} />
      <div
        className={classNames(styles.secondaryMatters, {
          [styles["__no-offset"]]: !smallItems.length,
        })}
      >
        {smallItems.map((matter, i) => (
          <DesktopSmallItem
            key={i}
            matter={matter}
            className={smallItemClassName}
          />
        ))}
      </div>
      {secondStory && <DesktopStory item={secondStory} />}
    </div>
  );
};

export default DayPictureDesktop;
