import React, { FunctionComponent } from "react";
import styles from "../styles/components/LiveHint.module.scss";
import classNames from "classnames";

const LiveHint: FunctionComponent<{ className?: string }> = (props) => {
  return (
    <div className={classNames(styles.live, props.className)}>
      <em>•</em>
      <span>Live</span>
    </div>
  );
};

export default LiveHint;
